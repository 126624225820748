import { Alert, Box, Fade, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const AccountMissingPage = () => {
    const { t } = useTranslation()

    return (
        <Box display='flex' justifyContent='center' alignItems='center' sx={{ width: 1, minHeight: '100vh' }}>
            <Alert severity={'warning'}>{t('accountMissingMsg')}</Alert>
        </Box>
    )
}

export default AccountMissingPage
