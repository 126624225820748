import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Provider, useLazyExchangeCodeQuery } from "./authApiSlice"

const Callback = ({ provider }: { provider: Provider }) => {
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    const code = searchParams.get('code')
    const state = searchParams.get('state')
    const [error, setError] = useState<string | null>(null)

    const [exchangeCodeApi] = useLazyExchangeCodeQuery()

    async function exchangeCode() {
        if (!code) {
            setError('No code provided')
            return
        }

        try {
            await exchangeCodeApi({ provider, code })
            navigate('/', { replace: true })
        } catch (error) {
            setError((error as FetchBaseQueryError).data as string)
            return
        }
    }

    useEffect(() => {
        // Compare the state to the one in local storage
        const storedState = localStorage.getItem('state')
        if (state !== storedState) {
            setError('State mismatch')
            return
        }

        exchangeCode()
    }, [])

    return <div>{error ? error : 'Exchanging code...'}</div>
}

export default Callback
