import { apiSlice } from "../app/apiSlice"
import { Account } from "../model/account"

export enum Provider {
    Google = 'google',
}

type ExchangeCodeRequest = {
    provider: Provider
    code: string
}

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        exchangeCode: builder.query<void, ExchangeCodeRequest>({
            query: ({ provider, code }) => `/backend/${provider}/exchange?code=${code}`,
        }),
        getAccount: builder.query<Account, void>({
            query: () => '/backend/account',
        }),
        logout: builder.mutation<void, void>({
            query: () => ({ url: '/backend/logout', method: 'POST' }),
        }),
    }),
})

export const {
    useLazyExchangeCodeQuery,
    useGetAccountQuery,
    useLazyGetAccountQuery,
    useLogoutMutation,
} = authApiSlice
