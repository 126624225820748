import { useCallback, useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../app/hooks'
import { useLazyGetAccountQuery } from './authApiSlice'
import { selectAccount } from './authSlice'



function RestrictedRoute() {
    const navigate = useNavigate()
    const location = useLocation()

    const [getAccount] = useLazyGetAccountQuery()
    const account = useAppSelector(selectAccount)

    const sessionChecker = useCallback(async () => {
        try {
            await getAccount().unwrap()
        } catch (error) {
            navigate('/', { replace: true, state: { from: location } })
        }
    }, [getAccount, navigate, location])

    // Check session every minute.
    useEffect(() => {
        const interval = setInterval(sessionChecker, 60000)
        return () => clearInterval(interval)
    }, [sessionChecker])

    if (!account) {
        return <Navigate to='/' replace state={{ from: location }} />
    }

    return <Outlet />
}

export default RestrictedRoute
