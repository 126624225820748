import { Box, Button } from '@mui/material'
import { useGoogleLogin } from '@react-oauth/google'
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'
import { useAppDispatch } from '../app/hooks'
import { useGetAccountQuery } from './authApiSlice'
import { setAccount } from './authSlice'

const AutoSignInPage = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const {
        data: account,
        isLoading,
        isError,
        error,
    } = useGetAccountQuery(undefined, { refetchOnMountOrArgChange: true })
    const state = useMemo(() => {
        const uuid = v4()
        localStorage.setItem('state', uuid)
        return uuid
    }, [])
    const loginGoogle = useGoogleLogin({
        onError: (error) => {
            console.log(error)
        },
        flow: 'auth-code',
        redirect_uri: `${window.location.origin}/google/callback`,
        state: state,
        ux_mode: 'redirect',
    })

    useEffect(() => {
        if (isLoading) {
            return
        }
        if (isError) {
            if ('status' in error && error.status === 403) {
                navigate('/account-missing', { replace: true })
            } else {
                loginGoogle()
            }
        }

        const from = location.state?.from || '/app/deployment'
        if (account) {
            dispatch(setAccount(account))
            navigate(from, { replace: true })
        }
    }, [account, isLoading, isError, location.state])

    return (
        <>
            <Box
                sx={{
                    my: 4,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Button onClick={() => loginGoogle()}>Sign in with Google</Button>
            </Box>
        </>
    )
}

export default AutoSignInPage
