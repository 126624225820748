import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { resetStore } from '../app/customActions'
import { useAppDispatch } from '../app/hooks'
import { RootState } from '../app/store'
import { Account } from '../model/account'
import { useLogoutMutation } from './authApiSlice'

type AuthState = {
    account: Account | null
}

const authSlice = createSlice({
    name: 'auth',
    initialState: { account: null } as AuthState,
    reducers: {
        setAccount: (state, action: PayloadAction<Account | null>) => {
            state.account = action.payload
        },
    },
})

export const {
    setAccount,
} = authSlice.actions

export default authSlice.reducer

export const useLogout = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [logoutMutation] = useLogoutMutation()

    return useCallback(() => {
        // Order is important here. resetStore() empties slice stores and clearAuthentication() makes it not wait for it to be loaded again.
        logoutMutation()
        localStorage.clear()
        dispatch(resetStore())
        dispatch(setAccount(null))

        navigate('/', { replace: true })
    }, [dispatch, logoutMutation])
}

export const selectAccount = (state: RootState) => state.auth.account
