import { Action, combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer from '../auth/authSlice'
import appMessageReducer, { errorListenerMiddleware } from '../dashboard/appMessageSlice'
import { apiSlice } from './apiSlice'
import { resetStore } from './customActions'

const combinedReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    appMessageHolder: appMessageReducer,
})


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: Action) => {
    if (action.type === resetStore.type) {
        state = undefined
    }
    return combinedReducer(state, action)
}

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware).prepend(errorListenerMiddleware.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
